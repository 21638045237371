:global(#app) {
  .cover {
    background: url("../../assets/images/cover.jpg") center / cover;
  }

  .descriptionSubtitle {
    font-size: 24px;
    font-weight: normal;
    margin-top: 8px;
  }

  .descriptionTitle {
    font-size: 52px;
    margin-bottom: 8px;
  }

  .descriptionWrapper {
    padding: 60px 0 0 80px;
    position: relative;
  }

  .descriptionWrapperOverlay {
    background: rgba(33, 33, 33, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .formTitle {
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 32px;
  }

  .fullHeight {
    height: 100%;
  }

  .fullHeightPaddingFix {
    height: calc(100% + 1rem);
  }

  .inputLabel {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .inputWrapper {
    margin-bottom: 16px;
  }

  .loginWrapper {
    margin: 0 auto;
    max-width: 440px;
    padding: 0 20px;
  }

  .wrapper {
    background: #fff;
  }

  .HeaderLoginTitle {
    color: rgb(32, 32, 32);
  }

  .developmentmode {
    background-color: #a81a1a;
    border-radius: 10px;
    color: #f37f7f;
    font-size: 2rem;
    margin-top: 1rem 2rem;
    padding: 10px 20px;
    display: inline-block;
    animation: fadeIn 1s ease 0s forwards;
  }

  .miniTextDJ {
    font-size: 1rem;
    color: #000000;
    margin-top: 10px;
    background-color: #b4e0b7;
    padding: 10px 20px;
    border-radius: 10px;
    display: inline-block;
    animation: fadeIn 1.5s ease 0s forwards;
  }

  .headerLogo {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;
    animation: spinner 3s ease 0s forwards infinite;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

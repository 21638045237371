:global(#app) {
  .menu {
    margin: -7px -12px -5px;
    width: calc(100% + 24px);
  }

  .menuItem {
    margin: 0;
    padding-left: 14px;
    color: #202020;
  }

  :global(.colorTextWhite) {
    color: #dbdbdb !important;
    transition: color 200ms ease;
  }

  :global(.colorTextWhite):hover {
    color: #b6b6b6 !important;
  }
}

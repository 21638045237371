:global(#app) {
  .deleteButton {
    bottom: 12px;
    box-shadow: 0 1px 0 rgb(153, 18, 18);
    background-color: rgb(153, 18, 18);
    color: rgb(230, 136, 136);
    position: absolute;
    right: 9px;
    transition: all 0.3s ease;
  }

  .deleteButton:hover {
    box-shadow: 0 1px 0 rgb(134, 15, 15);
    background-color: rgb(134, 15, 15);
    color: rgb(184, 86, 86);
  }
}

:global(#app) {
  .controls {
    clear: both;
    margin-top: 6px;
  }

  .field {
    background: #22272B;
    color: #ccc;
    display: block;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 4px;
    overflow: hidden;
    resize: none;

    &:focus {
      outline: none;
    }
  }
}

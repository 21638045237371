:global(#app) {
  .wrapper {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    position: fixed;
    width: 100%;
    z-index: -1;
  }
}

:global(#app) {
  .divider {
    background: #eee;
    border: 0;
    height: 1px;
    margin-bottom: 8px;
  }

  .menu {
    margin: -7px -12px -5px;
    width: calc(100% + 24px);
  }

  .menuItem {
    margin: 0;
    margin: 1rem;
    padding-left: 14px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235A5A5AFF' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  .tip {
    opacity: 0.5;
    color: white;
  }
}

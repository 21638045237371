:global(#app) {
  .menuItem {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 4px;
  }

  .menuItemActive {
    background: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .menuItemText {
    display: inline-block;
    line-height: 32px;
    width: calc(100% - 40px);
    color: white;
    transition: color 200ms ease;
  }

  .menuItemText:hover {
    color: #a3adb3;
  }

  .menuItemTextActive:before {
    bottom: 2px;
    color: #798d99;
    content: "Г";
    font-size: 18px;
    font-weight: normal;
    line-height: 36px;
    position: absolute;
    right: 2px;
    text-align: center;
    transform: rotate(-135deg);
    width: 36px;
  }

  .user {
    display: inline-block;
    line-height: 32px;
    padding-right: 8px;
    width: 40px;
  }
}

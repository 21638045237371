:global(#app) {
  .field {
    border: none;
    margin-bottom: 4px;
    outline: none;
    overflow: hidden;
    padding: 0;
    resize: none;
    width: 100%;
  }

  .fieldWrapper {
    background: #252525;
    border-radius: 3px;
    box-shadow: 0 0px 0 #ccc;
    color: white;
    margin-bottom: 8px;
    min-height: 20px;
    padding: 6px 8px 2px;
  }

  .submitButton {
    vertical-align: top;
  }

  .wrapper {
    padding-bottom: 8px;
  }

  .wrapperClosed {
    display: none;
  }
}

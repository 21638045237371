:global(#app) {
  .deleteButton {
    bottom: 12px;
    background-color: rgb(255, 99, 99);
    color:rgb(104, 14, 14);
    position: absolute;
    right: 9px;

    &:hover {
      background-color: rgb(184, 56, 56);
      color:rgb(41, 6, 6);
    }
  }

  .field {
    margin-bottom: 8px;
  }

  .text {
    color: #cccccc;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 6px;
  }
}

:global(#app) {
  .field {
    margin-bottom: 8px;
  }

  .text {
    color: rgb(241, 241, 241);
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 6px;
  }
}

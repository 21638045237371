:global(#app) {
  .field {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: none;
    color: #cccccc;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin: -5px;
    overflow: hidden;
    padding: 4px;
    resize: none;
    width: 100%;

    &:focus {
      background: #212121;
      border-color: #5ba4cf;
      box-shadow: 0 0 2px 0 #5ba4cf;
      outline: 0;
    }
  }
}

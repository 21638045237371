:global(#app) {
  .content {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  .contentPdf {
    height: 100%;
    width: 1120px;
  }

  .contentError {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    height: 20px;
    width: 470px;
  }

  .toggleButton {
    background: transparent;
    box-shadow: none;
    color: #6b808c;
    font-weight: normal;
    margin-top: 8px;
    padding: 6px 11px;
    text-align: left;
    text-decoration: underline;
    transition: none;

    &:hover {
      background: rgba(9, 30, 66, 0.08);
      color: #092d42;
    }
  }
}
